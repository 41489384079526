<script setup>
import { onMounted, ref } from 'vue';
import requests from '../../OfEventRequests';
import OfTable from 'of_vue_utils/src/utils/OfTable.vue';
import OfFormatDateTime from 'of_vue_utils/src/utils/OfFormatDateTime.vue';
import OfButton from 'of_vue_utils/src/utils/OfButton.vue';
import { correctionTypes } from '@/config';

const corrections = ref([])

const newCorrection = ref({})

const sumOfCorrections = ref(0)
const sumOfEnabledCorrections = ref(0)

function getSumOfCorrections() {
    sumOfCorrections.value = corrections.value ? corrections.value.reduce((prevSum, correction) => prevSum + Number(correction.amount), 0) : null;
}
function getSumOfEnabledCorrections() {
    sumOfEnabledCorrections.value = corrections.value ? corrections.value.reduce((prevSum, correction) => prevSum + (correction.enabled ? Number(correction.amount) : 0), 0) : null;
}
function getCorrections() {
    requests.get.corrections().then((result) => {
        corrections.value = result;

        getSumOfCorrections();
        getSumOfEnabledCorrections();
    })
}
function insertCorrection() {
    requests.post.correction(newCorrection.value).then(() => {
        getCorrections();

        newCorrection.value = {};
    });
}
function updateCorrection(correction, state) {
    let message = '';

    if (state === false || state === true) {
        correction.enabled = state;
    } else {
        message = 'Korrektion opdateret';
    }
    requests.put.correction(correction, correction.correctionGuid, message).then(() => {
        getSumOfCorrections();
        getSumOfEnabledCorrections();
    })
}
function deleteCorrection(correction) {
    requests.delete.correction(correction.correctionGuid).then(() => {
        getCorrections();
    });
}
onMounted(() => {
    getCorrections();

})
</script>
<template>
    <div class="heading-wrapper">
        <h2 class="title">Korrektioner</h2>
        <p class="monospace">
            Sum af alt: {{ sumOfCorrections.toLocaleString('da-DK',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} DKK
        </p>
        <p class="monospace">
            Sum af aktive: {{ sumOfEnabledCorrections.toLocaleString('da-DK',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} DKK
        </p>
    </div>

    <OfTable type="entity">
        <template #thead>
            <th>Tidspunkt</th>
            <th>Beløb</th>
            <th>Type</th>
            <th>Beskrivelse</th>
            <th></th>
        </template>

        <template #tbody>
            <tr>
                <td>Opret ny</td>

                <td class="amount">
                    <input type="number" placeholder="Beløb" v-model="newCorrection.amount" />
                </td>

                <td>
                    <select v-model="newCorrection.type">
                        <option v-for="(type, index) in correctionTypes" :key="index" :value="index">
                            {{ type }}
                        </option>

                    </select>
                </td>

                <td class="correction-description">
                    <textarea v-model="newCorrection.description" />

                    <span style="visibility: hidden;">Description</span>
                </td>

                <td class="actions">
                    <OfButton text="Registrér" coloring="primary" size="small" @click="insertCorrection()" />
                </td>
            </tr>

            <tr v-for="correction in corrections" :key="correction.correctionGuid"
                :class="correction.enabled ? 'green' : 'grey'">
                <td>
                    <OfFormatDateTime :date-time="correction.createdTs" />
                </td>
                <td class="amount" :class="correction.amount >= 0 ? 'positive' : 'negative'">
                    <input type="number" v-model="correction.amount" />
                </td>
                <td>{{ correctionTypes[correction.type] }}</td>
                <td class="correction-description">
                    <textarea v-model="correction.description" />

                    <span style="visibility:hidden">
                        {{ correction.description }}
                    </span>
                </td>
                <td class="actions">
                    <OfButton v-if="!correction.enabled" text="Aktivér" coloring="primary" size="small"
                        @click="updateCorrection(correction, true)" />

                    <OfButton v-else text="Deaktivér" coloring="cancel" size="small"
                        @click="updateCorrection(correction, false)" />

                    <OfButton text="Opdatér" coloring="secondary" size="small" @click="updateCorrection(correction)" />

                    <OfButton text="Slet" coloring="cancel" size="small" @click="deleteCorrection(correction)" />

                </td>
            </tr>
        </template>
    </OfTable>
</template>

<style scoped>
.heading-wrapper h2,
.heading-wrapper p,
.heading-wrapper label {
    display: inline-block;
    vertical-align: top;
    line-height: 28px;
    margin: 0 0 1em;
    margin-right: 25px;
}

.heading-wrapper h2 {
    width: 175px;
    font-size: 1.1rem;
    font-weight: 500;
}

.monospace {
    font-family: monospace;
}

th:last-child {
    width: 230px;

}

td.negative {
    color: #E6492D;
}

td.positive {
    color: #38B249;
}

.correction-description textarea {
    overflow: hidden;
    margin-top: 4px;
    position: absolute;
    max-width: 165px;
    max-height: 35px;
}

.correction-description textarea:focus {
    overflow: auto;
    z-index: 2;
    position: absolute;
    min-width: 300px;
    min-height: 150px;
    max-height: revert;
    max-width: revert;
}

.actions :deep(button) {
    margin-right: 0.5rem;
}

input,
select,
textarea {
    color: #444;
    border: 1px solid #bbb;
    border-radius: 4px;
    display: block;
    max-width: none;
    background: white;
    padding: 5px 10px;
    transition: .2s all;
    outline: none;
    font-size: 14px;
    line-height: 1em;
    height: 35px;
    font-family: Roboto, sans-serif;
}

td.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>