import { correctionTypes, event } from "./config";

function phraseState(paymentOrState) {
    if (typeof paymentOrState === 'string') {
        const state = paymentOrState;

        switch (state) {
            case 'PENDING':
                return 'Godkend faktura'; // 'Til godkendelse';
            case 'APPROVED':
                return 'Godkend visning'; // 'Godkendt';
            case 'VALIDATED':
                return 'Godkendt'; // 'Valideret';
            case 'ACTIVE':
                return 'Godkendt'; // Event Payment state
            case 'REJECTED':
                return 'Afvist'; // Event Payment state
            case 'INACTIVE':
                return 'Afvist';
            case 'MERGED':
                return 'Flettet';
            default:
                return state;
        }
    } else {
        const payment = paymentOrState;

        if (payment.state == 'APPROVED' && !payment.updatedTs && !requestedAnonymized(payment)) {
            return 'Godkend visning'; // 'Godkent';
        }
        switch (payment.state) {
            case 'PENDING':
                return 'Godkend faktura'; // 'Til godkendelse';
            case 'APPROVED':
                return 'Godkendt'; // 'Valideret';
            case 'ACTIVE':
                return 'Godkendt'; // Event Payment state
            case 'REJECTED':
                return 'Afvist'; // Event Payment state
            case 'INACTIVE':
                return 'Afvist';
            case 'MERGED':
                return 'Flettet';
            default:
                return payment.state;
        }
    }
}

function loginExpired() {
    const nowInSeconds = Math.round(new Date().getTime() / 1000);

    const authExp = localStorage.getItem("authExp");

    return !authExp || (authExp && nowInSeconds > authExp);
}

function phrasePaymentMethodType(paymentMethodType) {
    switch (paymentMethodType) {
        case 'INVOICE':
            return 'Faktura';
        case 'CARD':
            return 'Betalingskort';
        case 'MOBILEPAY':
            return 'MobilePay';
        case 'TEST':
            return 'Test';
        case 'BETALINGSSERVICE':
            return 'Betalingsservice';
        case 'MOBILE_PAY_ONLINE':
            return 'MobilePay Online';
        case null:
            return 'Ikke oplyst';
        default:
            return paymentMethodType;
    }
}

function phraseContactType(contactType) {
    switch (contactType) {
        case 'INDIVIDUAL':
            return 'Privat';
        case 'BUSINESS':
            return 'Erhverv';
        default:
            return contactType;
    }
}

function phraseSource(source) {
    switch (source) {
        case 'FORM':
            return 'Formular';
        case 'ONBOARDING':
            return 'Telemarketing';
        case 'MANUAL':
            return 'Manuel';
        default:
            return source;
    }
}

function phraseChannel(channel) {
    switch (channel) {
        case 'CALLCENTER':
            return 'Callcenter';
        case 'WEBSITE':
            return 'Website';
        default:
            return channel;
    }
}
function formatHour(timestamp) {
    if (timestamp) {
        return timestamp.substring(11, 19);
    }
    return "";
}
function getStateColor(payment, type = 'hex') {
    if (payment.state == 'APPROVED' && !payment.updatedTs && !requestedAnonymized(payment))
        return type == 'name' ? 'yellow' : '#F6AB2F';
    else if (payment.state == 'APPROVED')
        return type == 'name' ? 'green' : '#38B249';
    else if (payment.state == 'ACTIVE')
        return type == 'name' ? 'green' : '#38B249';
    else if (payment.state == 'PENDING')
        return type == 'name' ? 'yellow' : '#F6AB2F';
    else if (payment.state == 'REJECTED')
        return type == 'name' ? 'red' : '#E6492D';
    else if (payment.state == 'INACTIVE')
        return type == 'name' ? 'red' : '#E6492D';
    else if (payment.state == 'MERGED')
        return type == 'name' ? 'blue' : '#1665D8';
}
/**
 * Enforce typing rules
 * @param {String} rule 
 * @param {Object} e 
 */
function enforceRule(rule, e) {
    if (rule === 'digits' || rule === 'number') {

        if ([191, 219].includes(e.keyCode)) {
            e.preventDefault();
        } else if ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) && (e.ctrlKey === true || e.metaKey === true)) {
            return;
        } else if ([8, 46, 9, 27, 13, 35, 36, 37, 39].includes(e.keyCode)) {
            return;
        } else if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)) {
            if (rule === 'number' && ((e.keyCode == 48 || e.keyCode == 96) && e.target.value.length < 1)) {

                e.preventDefault();
            }
            return;
        }

        e.preventDefault();

    } else if (rule === "text") {

        const keyCode = (e.keyCode ? e.keyCode : e.which);
        if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) {
            e.preventDefault();
        }

    } else if (rule === "email") {

        const keyCode = (e.keyCode ? e.keyCode : e.which);
        if (keyCode === 32) {
            e.preventDefault();
        }
    }
}
function phraseKeyFigure(keyFigure) {
    switch (keyFigure) {
        case 'betalingsservice':
            return 'Betalingsservice';
        case 'business':
            return 'Erhverv';
        case 'callcenter':
            return 'Callcenter';
        case 'card':
            return 'Kort';
        case 'individual':
            return 'Privat';
        case 'invoice':
            return 'Faktura';
        case 'manual':
            return 'Manuel';
        case 'import':
            return 'Import';
        case 'mobile_pay_online':
            return 'MobilePay';
        case 'sms':
            return 'SMS';
        case 'website':
            return 'Website';
        case 'other':
            return 'Ikke oplyst';
        case 'mobile_pay_external':
            return 'MobilePay External';
        case 'mobile_pay_external_source':
            return 'MobilePay External';
        case 'sms_source':
            return 'SMS donation';
        case 'sms_lottery_source':
            return 'SMS lotteri';
        default:
            return correctionTypes.value[keyFigure] ?? keyFigure
    }
}
function requestedAnonymized(payment) {
    if (event.value.showOnlyPaymentsByBusinessesOnBusinessCrawler) {
        return payment.requestedAnonymizedFromBusinessCrawler && payment.requestedAnonymizedFromIndividualCrawler;
    } else {
        return payment.requestedAnonymizedFromBusinessCrawler;
    }
}
export { phraseState, loginExpired, phrasePaymentMethodType, phraseContactType, phraseSource, phraseChannel, formatHour, getStateColor, enforceRule, phraseKeyFigure, requestedAnonymized }