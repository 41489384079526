<script setup>
import { onMounted, ref, computed, onUnmounted } from 'vue';
import apexchart from "vue3-apexcharts";

import OfTable from 'of_vue_utils/src/utils/OfTable.vue';
import OfSpinner from 'of_vue_utils/src/utils/OfSpinner.vue';
import OfButton from 'of_vue_utils/src/utils/OfButton.vue';

import requests from '../../OfEventRequests';
import { formatHour, phraseContactType, phrasePaymentMethodType, phraseKeyFigure } from '../../functions.js'
import { correctionTypes, event } from '@/config';
import OfWidget from 'of_vue_utils/src/utils/OfWidget.vue';

let chartData = null;

const showMainChartSeries = ref(true);

const chartXAxis = {
    type: "datetime",
}
const chartYAxis = {
    forceNiceScale: true,
    labels: {
        formatter: function (val) {
            return val ? val.toLocaleString("da") : val;
        },
    },
}

//  Main
const mainChartOptions = ref({
    chart: {
        id: 'apexSimpleChart',
        type: "area",
        zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: true,
        },
        animations: {
            dynamicAnimations: null
        }
    },
    colors: [
        "#008ffb",
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#be5dd0",
        "#d0cc5d",
        "#5dd0bb",
        "#5d8bd0",
    ],
    fill: {
        type: "solid",
        opacity: 1,
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        x: {
            format: "yyyy-MM-dd HH:mm:ss"
        },
    },
    xaxis: chartXAxis,
    yaxis: chartYAxis,
    noData: {
        text: "Data ikke fundet",
        align: "center",
        verticalAlign: "middle",
        style: {
            color: "#ddd",
            fontSize: "40px",
        },
    },
});
const mainChartSeries = ref([
    {
        name: "Indsamlet",
        type: "area",
        data: [],
    },
]);

//  Details
const detailOverviewOptions = ref({
    chart: {
        id: 'apexChartOverview',
        type: "area",
        stacked: true,
        zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: true,
        },
        animations: {
            dynamicAnimations: null
        }
    },
    colors: [
        "#775dd0",
        "#be5dd0",
        "#008ffb",
        "#5d8bd0",
        "#feb019",
        "#ff4560",
        "#d0cc5d",
        "#5dd0bb",
        "#00e396",
    ],
    fill: {
        type: "solid",
        opacity: 1,
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        shared: true,
        x: {
            format: "yyyy-MM-dd HH:mm:ss"
        },
    },
    xaxis: {
        type: "datetime",
    },
    yaxis: {
        labels: {
            formatter: function (val) {
                return val ? val.toLocaleString("da") : val;
            },
        },
    },
    noData: {
        text: "Data ikke fundet",
        align: "center",
        verticalAlign: "middle",
        style: {
            color: "#ddd",
            fontSize: "40px",
        },
    },
})
const detailOverviewSeries = ref([]);

const topDonations = ref([]);
const newTopDonations = ref(false);
const dataLoaded = ref(false);
const paymentMethodTypes = ['invoice', 'card', 'mobile_pay_online', 'betalingsservice', 'sms', 'mobile_pay_external', 'other'];
const latestEventCount = ref(null);
const timeDifference = ref(0);
const tooEarly = ref(false);

const keyFigures = ref({
    contactType: {
        label: 'Kontakttype',
        stats: {
            business: {},
            individual: {}
        }
    },
    source: {
        label: 'Kilde',
        stats: {
            callcenter: {},
            website: {},
            manual: {},
            import: {},
            mobile_pay_external_source: {},
            sms_source: {},
            sms_lottery_source: {},
        }
    },

    paymentMethod: {
        label: 'Betalingsmetode',
        stats: {
        }
    },
    corrections: {
        label: 'Korrektioner',
        stats: {
        }
    },
    smsDonations: {
        label: 'SMS donationer',
        stats: {
        }
    },
    smsLottery: {
        label: 'SMS lotteri',
        stats: {
        }
    }

})
const currentAggregationFrequencyInMinutesString = computed(() => {
    if (event.value) {

        // TODO : Undersøg overoverenstemmelse mellem aggregationFrequencyInMinutes og det faktum at den rent faktisk tæller hver time
        const frequencyInMinutes = event.value.aggregationFrequencyInMinutes;

        //  Default
        let frequencyText = frequencyInMinutes + ". min.";

        //  Parse hours
        if (frequencyInMinutes > 60) {
            //  console.log(frequencyInMinutes)
            if (frequencyInMinutes / 60 === 1) {
                frequencyText = "time";
            } else {
                frequencyText = frequencyInMinutes / 60 + ". time";
            }
        }

        return frequencyText;
    } else {
        return "15. min.";
    }
})

function buildSimpleChartSeries() {
    if (chartData.EVENT) {
        mainChartSeries.value[0].data = chartData.EVENT.map((a) => {
            return { x: a.statusTs, y: a.scaledSumOfDonations };
        });
    }

    return null;
}
/**
 * Helper function to map status types
 */
function mapStatusType(statusType) {

    if (typeof chartData[statusType] !== "undefined") {
        return chartData[statusType].filter(function (obj) {

            //  TODO : Remove after test
            // if (new Date(obj.statusTs) > new Date('2023-08-14')) {
            //     return obj;
            // }
            return obj;
        }).map((a) => {

            return [new Date(a.statusTs).getTime(), a.scaledSumOfDonations];
        });
    } else {
        return [];
    }
}
function buildDetailedChartSeries() {
    detailOverviewSeries.value = [];

    if (typeof chartData['SMS_LOTTERY'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "SMS Lodtrækning", // 0
            data: mapStatusType("SMS_LOTTERY"),
        });
    }
    if (typeof chartData['SMS'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "SMS Donation", // 1
            data: mapStatusType("SMS"),
        });
    }
    if (typeof chartData['MOBILE_PAY_EXTERNAL'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "MobilePay External", // 2
            data: mapStatusType("MOBILE_PAY_EXTERNAL"),
        });
    }
    if (typeof chartData['MOBILE_PAY_ONLINE'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "MobilePay Online", // 3
            data: mapStatusType("MOBILE_PAY_ONLINE"),
        });
    }
    if (typeof chartData['CARD'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "Betalingskort", // 4
            data: mapStatusType("CARD"),
        });
    }
    if (typeof chartData['BETALINGSSERVICE_SINGLE'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "Betalingsservice", // 5
            data: mapStatusType("BETALINGSSERVICE_SINGLE"),
        });
    }
    if (typeof chartData['CORRECTION'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "Korrektioner", // 6
            data: mapStatusType("CORRECTION"),
        });
    }
    if (typeof chartData['BUSINESS_PAYMENT'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "Donationer til TV-Crawler", // 7
            data: mapStatusType("BUSINESS_PAYMENT"),
        });
    }
    if (typeof chartData['EVENT_PAYMENT'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "Donationer under beløbsgrænse", // 8
            data: mapStatusType("EVENT_PAYMENT"),
        });
    }
    if (typeof chartData['SMS_THIRD_PARTY'] !== "undefined") {
        detailOverviewSeries.value.push({
            name: "SMS 3. part", // 9
            data: mapStatusType("SMS_THIRD_PARTY"),
        });
    }

}
function getAmountByPaymentMethod(dataPoints, paymentMethodType) {
    if (dataPoints) {

        const latestDataPoint = dataPoints[dataPoints.length - 1];
        const details = latestDataPoint.details;
        if (details) {

            const filteredDetails = details.filter((detail) => paymentMethodType ? (detail.paymentMethodType ?? '').toLowerCase() == paymentMethodType.toLowerCase() : (!detail.paymentMethodType || detail.paymentMethodType == '') || !paymentMethodTypes.includes(detail.paymentMethodType.toLowerCase()))

            if (filteredDetails) {
                const sumAmount = filteredDetails.reduce((prevAmount, detail) => prevAmount + detail.sumOfDonations, 0)
                return sumAmount;
            }

        }
    }

    return 0;
}
function getCountByPaymentMethod(dataPoints, paymentMethodType) {
    if (dataPoints) {

        const latestDataPoint = dataPoints[dataPoints.length - 1];
        const details = latestDataPoint.details;
        if (details) {

            const filteredDetails = details.filter((detail) => paymentMethodType ? (detail.paymentMethodType ?? '').toLowerCase() == paymentMethodType.toLowerCase() : (!detail.paymentMethodType || detail.paymentMethodType == '') || !paymentMethodTypes.includes(detail.paymentMethodType.toLowerCase()))

            if (filteredDetails) {
                const sumCount = filteredDetails.reduce((prevCount, detail) => prevCount + detail.countOfDonations, 0)
                return sumCount;
            }

        }
    }

    return 0;
}
function getAmount(dataPoints) {
    if (dataPoints) {
        const latestDataPoint = dataPoints[dataPoints.length - 1];
        return latestDataPoint.scaledSumOfDonations;
    }

    return 0;
}
function getCount(dataPoints) {
    if (dataPoints) {
        const latestDataPoint = dataPoints[dataPoints.length - 1];
        return latestDataPoint.countOfDonations;
    }

    return 0;
}
function getCorrectionAmount(dataPoints, correctionType) {
    if (dataPoints) {
        const latestDataPoint = dataPoints[dataPoints.length - 1];
        const detail = latestDataPoint.details ? latestDataPoint.details.find((detail) => detail.correctionType == correctionType) : null
        return detail ? detail.scaledSumOfDonations : null;
    }

    return 0;
}
function defineLatestEventCount() {
    if (typeof chartData["EVENT"] !== "undefined") {
        const lastestCollection = chartData["EVENT"].length - 1;
        if (latestEventCount.value) {
            timeDifference.value = Date.parse(chartData["EVENT"][lastestCollection].statusTs) - Date.parse(latestEventCount.value.statusTs)
        }
        latestEventCount.value = chartData["EVENT"][lastestCollection];
        //  console.log(chartData["EVENT"][lastestCollection].statusTs);
    }
}

function buildkeyFigures() {

    keyFigures.value.contactType.stats.business.amount = getAmount(chartData.CONTACT_BUSINESS)

    keyFigures.value.contactType.stats.individual.amount = getAmount(chartData.CONTACT_INDIVIDUAL) + getAmount(chartData.SMS_LOTTERY) + getAmount(chartData.SMS) + getAmount(chartData.MOBILE_PAY_EXTERNAL)

    keyFigures.value.source.stats.callcenter.amount = getAmount(chartData.CHANNEL_CALLCENTER)

    keyFigures.value.source.stats.website.amount = getAmount(chartData.CHANNEL_WEBSITE)

    keyFigures.value.source.stats.manual.amount = getAmount(chartData.SOURCE_MANUAL)

    keyFigures.value.source.stats.import.amount = getAmount(chartData.SOURCE_IMPORT)

    keyFigures.value.source.stats.mobile_pay_external_source.amount = getAmount(chartData.MOBILE_PAY_EXTERNAL)

    keyFigures.value.source.stats.sms_source.amount = getAmount(chartData.SMS)

    keyFigures.value.source.stats.sms_lottery_source.amount = getAmount(chartData.SMS_LOTTERY)

    keyFigures.value.contactType.stats.business.count = getCount(chartData.CONTACT_BUSINESS)

    keyFigures.value.contactType.stats.individual.count = getCount(chartData.CONTACT_INDIVIDUAL) + getCount(chartData.SMS_LOTTERY) + getCount(chartData.SMS) + getCount(chartData.MOBILE_PAY_EXTERNAL)

    keyFigures.value.source.stats.callcenter.count = getCount(chartData.CHANNEL_CALLCENTER)

    keyFigures.value.source.stats.website.count = getCount(chartData.CHANNEL_WEBSITE)

    keyFigures.value.source.stats.manual.count = getCount(chartData.SOURCE_MANUAL)

    keyFigures.value.source.stats.import.count = getCount(chartData.SOURCE_IMPORT)

    keyFigures.value.source.stats.mobile_pay_external_source.count = getCount(chartData.MOBILE_PAY_EXTERNAL)

    keyFigures.value.source.stats.sms_source.count = getCount(chartData.SMS)

    keyFigures.value.source.stats.sms_lottery_source.count = getCount(chartData.SMS_LOTTERY)

    paymentMethodTypes.forEach(paymentMethodType => {

        keyFigures.value.paymentMethod.stats[paymentMethodType] = {};

        if (paymentMethodType == 'card' || paymentMethodType == 'mobile_pay_online') {

            keyFigures.value.paymentMethod.stats[paymentMethodType].amount = getAmountByPaymentMethod(chartData.BUSINESS_PAYMENT, paymentMethodType) + getAmountByPaymentMethod(chartData.EVENT_PAYMENT, paymentMethodType) + getAmount(chartData[paymentMethodType.toUpperCase()])
            keyFigures.value.paymentMethod.stats[paymentMethodType].count = getCountByPaymentMethod(chartData.BUSINESS_PAYMENT, paymentMethodType) + getCountByPaymentMethod(chartData.EVENT_PAYMENT, paymentMethodType) + getCount(chartData[paymentMethodType.toUpperCase()])

        } else if (paymentMethodType == 'sms') {

            keyFigures.value.paymentMethod.stats[paymentMethodType].amount = getAmount(chartData['SMS']) + getAmount(chartData['SMS_LOTTERY']) + getAmount(chartData['SMS_THIRD_PARTY']);
            keyFigures.value.paymentMethod.stats[paymentMethodType].count = getCount(chartData['SMS']) + getCount(chartData['SMS_LOTTERY']) + getCount(chartData['SMS_THIRD_PARTY']);

        } else if (paymentMethodType == 'mobile_pay_external') {

            keyFigures.value.paymentMethod.stats[paymentMethodType].amount = getAmount(chartData['MOBILE_PAY_EXTERNAL']);
            keyFigures.value.paymentMethod.stats[paymentMethodType].count = getCount(chartData['MOBILE_PAY_EXTERNAL']);

        } else if (paymentMethodType == 'other') {

            // all data with a null paymentMethodType is pushed to "other" in they keyFigures
            keyFigures.value.paymentMethod.stats[paymentMethodType].amount = getAmountByPaymentMethod(chartData.BUSINESS_PAYMENT, null) + getAmountByPaymentMethod(chartData.EVENT_PAYMENT, null)
            keyFigures.value.paymentMethod.stats[paymentMethodType].count = getCountByPaymentMethod(chartData.BUSINESS_PAYMENT, null) + getCountByPaymentMethod(chartData.EVENT_PAYMENT, null)

        } else {

            keyFigures.value.paymentMethod.stats[paymentMethodType].amount = getAmountByPaymentMethod(chartData.BUSINESS_PAYMENT, paymentMethodType) + getAmountByPaymentMethod(chartData.EVENT_PAYMENT, paymentMethodType)
            keyFigures.value.paymentMethod.stats[paymentMethodType].count = getCountByPaymentMethod(chartData.BUSINESS_PAYMENT, paymentMethodType) + getCountByPaymentMethod(chartData.EVENT_PAYMENT, paymentMethodType)

        }

    });

    Object.keys(correctionTypes.value).forEach(correctionType => {

        const correctionAmount = getCorrectionAmount(chartData['CORRECTION'], correctionType);
        keyFigures.value.corrections.stats[correctionType] = {
            amount: correctionAmount ?? 0,
        }

    });

    // Get unspecified corrections
    if (chartData['CORRECTION']) {
        const latestDataPoint = chartData['CORRECTION'][chartData['CORRECTION'].length - 1];
        if (!latestDataPoint.details) {
            latestDataPoint.scaledSumOfDonations
            keyFigures.value.corrections.stats['Korrektioner'] = {
                amount: latestDataPoint.scaledSumOfDonations ?? 0,
            }
        }
    }

    if (chartData.SMS) {
        const latestDataPoint = chartData.SMS[chartData.SMS.length - 1];
        if (latestDataPoint.details) {
            latestDataPoint.details.forEach(detail => {
                keyFigures.value.smsDonations.stats[detail.smsKeyword ?? 'Total'] = {};
                keyFigures.value.smsDonations.stats[detail.smsKeyword ?? 'Total'].amount = detail.scaledSumOfDonations;
                keyFigures.value.smsDonations.stats[detail.smsKeyword ?? 'Total'].count = detail.countOfDonations;

            });
        }
    }

    if (chartData.SMS_LOTTERY) {
        const latestDataPoint = chartData.SMS_LOTTERY[chartData.SMS_LOTTERY.length - 1];
        if (latestDataPoint.details) {
            latestDataPoint.details.forEach(detail => {
                keyFigures.value.smsLottery.stats[detail.smsKeyword ?? 'Total'] = {};
                keyFigures.value.smsLottery.stats[detail.smsKeyword ?? 'Total'].amount = detail.scaledSumOfDonations;
                keyFigures.value.smsLottery.stats[detail.smsKeyword ?? 'Total'].count = detail.countOfDonations;

            });
        }
    }
}

let endOfTheLine = true;
function zoomed(a, { yaxis, xaxis }) {
    mainChartOptions.value.xaxis.min = xaxis.min;
    mainChartOptions.value.xaxis.max = xaxis.max;

    if (yaxis) {
        mainChartOptions.value.yaxis = yaxis;
    }

    if (xaxis.max && xaxis.min && xaxis.max < Date.parse(latestEventCount.value.statusTs)) {
        endOfTheLine = false;
    } else {
        endOfTheLine = true;
    }
    //  console.log(endOfTheLine);
}
async function getChartData() {
    chartData = await requests.get.eventChartData();
    defineLatestEventCount();
}
function triggerAggregation() {
    requests.post.triggerAggregation().then((result) => {
        if (result == 'Too early') {
            tooEarly.value = true;
        } else {
            tooEarly.value = false;
        }
    });
}
async function getTopDonations() {
    requests.get.businessPayments('?type=VALIDATED&pageNumber=1&pageSize=5&sorting=AMOUNT').then((result) => {
        if (result) {

            if (topDonations.value.length) {

                result.forEach((payment) => {
                    if (!topDonations.value.some((topDonation) => topDonation.businessPaymentGuid == payment.businessPaymentGuid)) {
                        newTopDonations.value = true;
                        setTimeout(() => {
                            newTopDonations.value = false;
                        }, 5000);
                    }
                })
            }

            topDonations.value = result;
        }
    });
}

let interval;
onMounted(async () => {

    await getChartData();

    buildSimpleChartSeries();
    buildDetailedChartSeries();

    buildkeyFigures()

    dataLoaded.value = true;

    interval = setInterval(async () => {

        await getChartData();

        buildkeyFigures();

        if (endOfTheLine && timeDifference.value > 0) {

            buildSimpleChartSeries();
            buildDetailedChartSeries();

            if (mainChartOptions.value.xaxis.min && mainChartOptions.value.xaxis.max) {
                mainChartOptions.value.xaxis.min += timeDifference.value;
                mainChartOptions.value.xaxis.max += timeDifference.value;
            }
            mainChartOptions.value.yaxis = chartYAxis;

            window.ApexCharts.exec("apexSimpleChart", "updateOptions", mainChartOptions.value);

            setTimeout(async () => {
                window.ApexCharts.exec("apexSimpleChart", "zoomX", mainChartOptions.value.xaxis.min, mainChartOptions.value.xaxis.max);
            }, 10);
        }

        await getTopDonations();
    }, 10000);

    await getTopDonations();
})
onUnmounted(() => {
    clearInterval(interval)
})
</script>

<template>
    <OfSpinner v-if="!dataLoaded" />

    <template v-else>

        <!-- Easter egg :o) -->
        <p v-if="newTopDonations" class="new-top-donation">
            Top donationer har ændret sig
        </p>

        <div v-if="latestEventCount" class="chart-counter">
            <div class="chart-total-sum"
                :title="`Ikke-skaleret: ${latestEventCount.sumOfDonations.toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} DKK`">
                Indsamlet
                <p>
                    {{ latestEventCount.scaledSumOfDonations.toLocaleString('da-DK', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) }} DKK
                </p>
            </div>
            <div class="chart-total-count">
                Antal donationer
                <p>
                    {{ latestEventCount.countOfDonations.toLocaleString('da-DK', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) }}
                </p>
            </div>
            <div class="chart-count-timestamp">
                Optælles hvert
                {{ currentAggregationFrequencyInMinutesString }}
                <p title="Seneste optælling">
                    {{ formatHour(latestEventCount.statusTs) }}
                </p>
                <div class="new-count">
                    <OfButton text="Start ny optælling" @click="triggerAggregation" />
                </div>
            </div>
        </div>

        <h2>Udvikling</h2>

        <div class="chart-wrapper overview">
            <div class="chart-buttons">
                <button :class="{ active: showMainChartSeries }" @click="showMainChartSeries = true">Overblik</button>
                <button :class="{ active: !showMainChartSeries }" @click="showMainChartSeries = false">Detaljer</button>
            </div>

            <!-- Main -->
            <div class="chart" :style="{ display: showMainChartSeries ? 'block' : 'none' }">
                <apexchart v-if="dataLoaded" :options="mainChartOptions" :series="mainChartSeries" height="400"
                    @zoomed="zoomed" />
            </div>

            <!-- Detail -->
            <div class="chart" :style="{ display: !showMainChartSeries ? 'block' : 'none' }">
                <apexchart v-if="dataLoaded" :options="detailOverviewOptions" :series="detailOverviewSeries"
                    height="400" />
            </div>
        </div>

        <p v-if="tooEarly" style="margin-top:10px; color:#E6492D">For tidligt at starte ny optælling</p>

        <div class="content-bottom">

            <h2>Fordeling</h2>

            <div class="key-figures">
                <OfWidget>
                    <template #content>
                        <OfTable type="widgetinfo">
                            <template #tbody>
                                <tr>
                                    <td></td>
                                    <td>Antal</td>
                                    <td>Beløb</td>
                                </tr>

                                <template v-for="(category) in keyFigures" :key="category.label">
                                    <tr class="figure-header">
                                        <td colspan=3>{{ category.label }}</td>
                                    </tr>

                                    <template v-for="(keyFigure, index) in category.stats" :key="index">
                                        <tr v-if="keyFigure.amount">
                                            <td>{{ phraseKeyFigure(index) }}</td>
                                            <td v-if="keyFigure.count">
                                                {{ keyFigure.count.toLocaleString('da-DK', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) }}
                                            </td>
                                            <td :colspan="!keyFigure.count ? 2 : 1">
                                                {{ keyFigure.amount.toLocaleString('da-DK', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) }}
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </OfTable>
                    </template>
                </OfWidget>
            </div>

            <h2>Top 5 donationer</h2>

            <div class="top-five">

                <OfTable type="entity">
                    <template #thead>
                        <th>CVR-nr.</th>
                        <th>Firmanavn</th>
                        <th style="width: 250px;">Visningsnavn</th>
                        <th>Beløb</th>
                        <th>Kontakttype</th>
                        <th title="Betalingsmetode" style="overflow: hidden; text-overflow: ellipsis;">Betalingsmetode
                        </th>
                    </template>

                    <template v-if="topDonations" #tbody>
                        <tr v-for="payment in topDonations" @keyup="keyup" v-bind:key="payment.businessPaymentGuid"
                            tabindex="0">
                            <td :title="payment.businessCode">
                                {{ payment.businessCode }}
                            </td>
                            <td :title="payment.companyName">
                                {{ payment.companyName }}
                            </td>
                            <td :title="payment.displayName">
                                {{ payment.displayName }}
                            </td>
                            <td :title="payment.amount">
                                {{ payment.amount.toLocaleString('da-DK', {
                                minimumFractionDigits: 0, maximumFractionDigits: 0
                                }) }}
                            </td>
                            <td :title="phraseContactType(payment.contactType)">
                                {{ phraseContactType(payment.contactType) }}
                            </td>
                            <td :title="phrasePaymentMethodType(payment.paymentMethodType)">
                                {{ phrasePaymentMethodType(payment.paymentMethodType) }}
                            </td>
                        </tr>
                    </template>
                </OfTable>
            </div>
        </div>
    </template>
</template>

<style scoped>
.new-top-donation {
    position: fixed;
    top: 30px;
    left: 50%;
    text-align: center;
    z-index: 9999;
    color: rgb(0, 100, 0);
    font-size: 14px;
    font-weight: 500;
    animation: flash .5s linear alternate infinite;
    width: 257px;
    text-align: center;
}

@keyframes flash {
    0% {
        font-size: 14px;
        color: rgba(0, 100, 0, .7);
    }

    100% {
        font-size: 14.5px;
        color: rgb(0, 100, 0);
    }
}

.chart-counter {
    font-size: 18px;
    color: rgba(0, 0, 0, .75);
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
}

.chart-counter p {
    font-size: 32px;
    margin-top: 18px;
    margin-bottom: 15px;
    color: #000;
}

.chart-counter span {
    font-size: 16px;
    opacity: 0.75
}

.chart-counter .new-count {
    margin: 6px 0 0;
}

.chart-counter .new-count .of-btn-base {
    padding: 0 6px;
    font-size: 11px;
    height: 22px;
}

.chart-counter .chart-total-count {
    text-align: center;
}

.chart-counter .chart-count-timestamp {
    text-align: right;
}

h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1rem;
}

h2:first-of-type {

    margin-bottom: 2.5rem;
}

.chart-wrapper {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px;
    position: relative;
    margin-bottom: 2rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.chart-buttons {
    position: absolute;
    top: -20px;
    display: flex;
    z-index: 99;
    background: white;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
}

.chart-buttons button {

    font-size: 11px;
    min-height: 22px;
    height: 22px;
    padding: 0 6px;

    font-weight: 500;
    position: relative;
    background: #fff;
    border: 1px solid #d8dce6;
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, .05), inset 0 2px 0 0 hsla(0, 0%, 100%, .05);
    color: #3e3f42;
    border-radius: 4px;

    transition: all .1s ease-in-out 0s;

    user-select: none;

    display: inline-flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;

    cursor: pointer;

}

.chart-buttons button:not(.active) {
    background: none;
    border: 1px solid transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, .5);
}

.chart-wrapper.overview>.chart {
    top: 30px;
    left: 0;
    width: 100%;
    height: 400px;
    background: #fff;
    z-index: -1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.key-figures {
    max-width: 550px;
}

.key-figures :deep(table) {
    border-collapse: separate;
    border-spacing: 0 5px;
}

.key-figures :deep(tr.row-break) {
    height: 30px;
}

.key-figures :deep(.of-widget-table-info td) {
    font-size: 1rem;
}

.key-figures :deep(.of-widget-table-info td:nth-child(n+2)) {
    text-align: right;
}

.key-figures :deep(.of-widget-table-info .figure-header >td) {
    font-size: 0.7rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #a5a5a5;
}

.key-figures tr:has(+ .figure-header):nth-child(n+2) {
    height: 50px;
}

.chart-wrapper.overview>.chart :deep(.apexcharts-toolbar) {
    top: -20px !important;
    z-index: 99;
    padding: 10px;
}

@media (min-width:1400px) {
    .content-bottom {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        column-gap: 20px;
        /* max-width: 1100px; */
        grid-template-areas:
            "header header"
            "graph graph";
    }

    .content-bottom>h2 {
        grid-area: header;
        grid-column: span 4/span 8;
    }

    .content-bottom>.key-figures {
        max-width: revert;
        grid-area: graph;
        grid-column: span 4/span 4;
    }

    .content-bottom>.top-five {
        max-width: revert;
        grid-area: graph;
        grid-column: span 8/span 8;
    }
}
</style>
