
import axios from "axios";
import md5 from 'js-md5'
import { apiUrl, user } from "./config.js";
import { toaster } from "./tools/toaster";
import { loginExpired } from "./functions";

export default {
    get: {
        businessPayments(queryString = '') {
            return get({
                url: apiUrl + `/businessPayments${queryString}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        searchBusinessPayments(queryString = '') {
            return get({
                url: apiUrl + `/businessPayments/search${queryString}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        countBusinessPayments(queryString = '') {
            return get({
                url: apiUrl + `/businessPayments/count${queryString}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        businessPayment(businessPaymentGuid) {
            return get({
                url: apiUrl + `/businessPayment/${businessPaymentGuid}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        businessPaymentRequests(queryString = '') {
            return get({
                url: apiUrl + `/businessPaymentRequests${queryString}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        claimedBusinessPayment(claimId) {
            return get({
                url: apiUrl + `/businessPayment/${claimId}/claimed`,
                Authorization: user.value.authToken,
                errorMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        mergeHistory(businessPaymentGuid) {
            return get({
                url: apiUrl + `/businessPayment/${businessPaymentGuid}/mergeHistory`,
                Authorization: user.value.authToken,
                errorMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        corrections() {
            return get({
                url: apiUrl + `/corrections`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        correction(correctionGuid) {
            return get({
                url: apiUrl + `/correction/${correctionGuid}`,
                Authorization: user.value.authToken,
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        event() {
            return get({
                url: apiUrl + `/event`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        eventChartData() {
            return get({
                url: apiUrl + `/event/chartData`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        eventPayments(queryString = '') {
            return get({
                url: apiUrl + `/eventPayments${queryString}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        searchEventPayments(queryString = '') {
            return get({
                url: apiUrl + `/eventPayments/search${queryString}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        eventPayment(eventPaymentGuid) {
            return get({
                url: apiUrl + `/eventPayment/${eventPaymentGuid}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        crawlerfile() {
            return get({
                url: apiUrl + `/crawlerfile`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        smsCampaigns() {
            return get({
                url: apiUrl + `/sms/campaigns`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        smsCampaign(campaignGuid) {
            return get({
                url: apiUrl + `/sms/campaigns/${campaignGuid}`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        smsCampaignWinners(campaignGuid) {
            return get({
                url: apiUrl + `/sms/campaign/${campaignGuid}/winners`,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
    },
    post: {
        businessPayment(businessPayment) {
            return post({
                url: apiUrl + `/businessPayment`,
                data: businessPayment,
                Authorization: user.value.authToken,
                successMessage: 'Donationen blev oprettet'
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        releaseBusinessPayment(businessPaymentGuid, data = {}) {
            return post({
                url: apiUrl + `/businessPayment/${businessPaymentGuid}/Release`,
                data: data,
                Authorization: user.value.authToken,
                successMessage: '',
                errorMessage: '',
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        claimBusinessPayment(businessPaymentGuid = null, type = null, contactType = null) {
            if (businessPaymentGuid) {
                return post({
                    url: apiUrl + `/businessPayment/${businessPaymentGuid}/Claim`,
                    data: {},
                    Authorization: user.value.authToken,
                    successMessage: '',
                    failureMessage: 'En anden medarbejder har donationen åben'
                }).then((response) => {
                    return response ? response.data : null;
                })
            }
            else if (type) {
                return post({
                    url: apiUrl + `/businessPayment/Claim?type=${type}${contactType ? '&contactType=' + contactType : ''}`,
                    data: {},
                    Authorization: user.value.authToken,
                    successMessage: '',
                    errorMessage: '',
                }).then((response) => {
                    return response ? response.data : null;
                })
            }
        },
        mergeBusinessPayment(sourceGuid, targetGuid, claim) {
            return post({
                url: apiUrl + `/businessPayment/${sourceGuid}/Merge`,
                data: { claim: claim, mergeTarget: { targetBusinessPaymentGuid: targetGuid } },
                Authorization: user.value.authToken,
                successMessage: 'Merged'
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        unmergeBusinessPayment(businessPaymentGuid, claim) {
            return post({
                url: apiUrl + `/businessPayment/${businessPaymentGuid}/Unmerge`,
                data: { claimId: claim.claimId },
                Authorization: user.value.authToken,
                successMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        resendReceiptBusinessPayment(businessPaymentGuid, claim) {
            return post({
                url: apiUrl + `/businessPayment/${businessPaymentGuid}/ResendReceipt`,
                data: claim,
                Authorization: user.value.authToken,
                successMessage: 'Receipt resent'
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        businessPaymentRequests(data) {
            return post({
                url: apiUrl + `/businessPaymentRequests/dataSet`,
                data: data,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        correction(correction) {
            return post({
                url: apiUrl + `/correction`,
                data: correction,
                Authorization: user.value.authToken,
                successMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        event(event) {
            return post({
                url: apiUrl + `/event`,
                data: event,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        eventPayment(eventPayment) {
            return post({
                url: apiUrl + `/eventPayment`,
                data: eventPayment,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        promoteEventPayment(eventPaymentGuid) {
            return post({
                url: apiUrl + `/eventPayment/${eventPaymentGuid}/Promote`,
                data: {},
                Authorization: user.value.authToken,
                successMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        archiveEventPayment(eventPaymentGuid) {
            return post({
                url: apiUrl + `/eventPayment/${eventPaymentGuid}/Archive`,
                data: {},
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        eventPaymentBatch(eventPayments) {
            return post({
                url: apiUrl + `/eventPayment/Batch`,
                data: eventPayments,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        stopSmsCampaign(campaignGuid) {
            return post({
                url: apiUrl + `/sms/campaign/${campaignGuid}/Stop`,
                data: {},
                Authorization: user.value.authToken,
                successMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        startSmsCampaign(campaignGuid) {
            return post({
                url: apiUrl + `/sms/campaign/${campaignGuid}/Start`,
                data: {},
                Authorization: user.value.authToken,
                successMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        drawSmsCampaignWinner(campaignGuid) {
            return post({
                url: apiUrl + `/sms/campaign/${campaignGuid}/DrawWinner`,
                data: {},
                Authorization: user.value.authToken,
                successMessage: 'Succes',
                errorMessage: 'Vinder(e) kunne ikke trækkes'
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        triggerAggregation() {
            return post({
                url: apiUrl + `/event/TriggerAggregation`,
                data: {},
                Authorization: user.value.authToken,
                successMessage: 'Ny optælling startet',
                failureMessage: 'Kunne ikke starte en ny optælling'
            }).then((response) => {
                if (response.response && response.response.status == 425) {
                    return 'Too early';
                }
                return response ? response.data : null;
            })
        }
    },
    put: {
        businessPayment(businessPaymentGuid, claim, businessPayment) {
            return put({
                url: apiUrl + `/businessPayment/${businessPaymentGuid}`,
                data: { claim: claim, updateDto: businessPayment },
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        correction(correction, correctionGuid, message = '') {
            return put({
                url: apiUrl + `/correction/${correctionGuid}`,
                data: correction,
                Authorization: user.value.authToken,
                successMessage: message
            }).then((response) => {
                return response ? response.data : null;
            })
        },
        event(event) {
            return put({
                url: apiUrl + `/event`,
                data: event,
                Authorization: user.value.authToken
            }).then((response) => {
                return response ? response.data : null;
            })
        },

    },
    delete: {
        correction(correctionGuid) {
            return del({
                url: apiUrl + `/correction/${correctionGuid}`,
                Authorization: user.value.authToken,
                successMessage: ''
            }).then((response) => {
                return response ? response.data : null;
            })
        },
    }
}

const isValidUrl = urlString => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}

/**
 * GET request
 * @param {Object} options
 * @returns Promise
 */
function get(options = {}) {

    if (isValidUrl(options.url)) {
        return axios.get(
            options.url,
            {
                headers: {
                    Authorization: options.Authorization
                }
            }
        ).then((response) => {
            extendLoginExpiration();
            return response;
        }).catch((error) => {
            if (!('errorMessage' in options) || options.errorMessage.length) {
                handleErrorResponse(error, options.errorMessage);
            }
        })
    } else {
        toaster.error('Url missing or invalid');
        return Promise.reject('Url missing or invalid');
    }
}

/**
 * POST request
 * @param {Object} options
 * @returns Promise
 */
function post(options = {}) {

    if (isValidUrl(options.url)) {

        //  form-data
        if (options.type == 'form-data') {

            if (!('successMessage' in options)) {
                options.successMessage = 'Created';
            }

            return axios.post(
                options.url,
                options.data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: options.Authorization,
                        //TODO md5
                    }
                }
            ).then((response) => {
                if (options.successMessage.length) {
                    toaster.success(options.successMessage);
                }
                return response;
            }).catch((error) => {
                if (!('errorMessage' in options) || options.errorMessage.length) {
                    handleErrorResponse(error, options.errorMessage);
                }
                return error;
            })
        }

        //  application/json
        else {
            const jsonData = JSON.stringify(options.data);

            //  Default successMessage
            if (!('successMessage' in options)) {
                options.successMessage = 'Created';
            }

            return axios.post(
                options.url,
                jsonData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: options.Authorization,
                        requestMD5: md5(jsonData),
                    }
                }
            ).then((response) => {
                if (options.successMessage.length) {
                    toaster.success(options.successMessage);
                }
                return response;
            }).catch((error) => {
                if (!('errorMessage' in options) || options.errorMessage.length) {
                    handleErrorResponse(error, options.errorMessage);
                }
                return error;
            })
        }
    } else {
        toaster.error('Url missing or invalid');
        return Promise.reject('Url missing or invalid');
    }
}

/**
 * PUT request
 * @param {Object} options
 * @returns Promise 
 */
function put(options = {}) {

    if (isValidUrl(options.url)) {
        const jsonData = JSON.stringify(options.data);

        //  Default successMessage
        if (!('successMessage' in options)) {
            options.successMessage = 'Updated';
        }

        return axios.put(
            options.url,
            jsonData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: options.Authorization,
                    requestMD5: md5(jsonData),
                }
            }
        ).then((response) => {
            if (options.successMessage.length) {
                toaster.success(options.successMessage);
            }
            return response;
        }).catch((error) => {
            if (!('errorMessage' in options) || options.errorMessage.length) {
                handleErrorResponse(error, options.errorMessage);
            }
        })
    } else {
        toaster.error('Url missing or invalid');
        return Promise.reject('Url missing or invalid');
    }
}
/**
 * DELETE request
 * @param {Object} options
 * @returns Promise
 */
function del(options = {}) {

    if (isValidUrl(options.url)) {

        //  Default successMessage
        if (!('successMessage' in options)) {
            options.successMessage = 'Deleted';
        }

        return axios.delete(
            options.url,
            {
                headers: {
                    Authorization: options.Authorization,
                }
            }
        ).then((response) => {
            if (options.successMessage.length) {
                toaster.success(options.successMessage);
            }
            return response;
        }).catch((error) => {
            if (!('errorMessage' in options) || options.errorMessage.length) {
                handleErrorResponse(error, options.errorMessage);
            }
        })
    } else {
        toaster.error('Url missing or invalid');
        return Promise.reject('Url missing or invalid');
    }
}
/**
 * Handle error response
 * @param {Object} AxiosError
 * @param {String} message 
 */
function handleErrorResponse(AxiosError, message = null) {
    if (loginExpired()) {
        window.location.reload()
    }

    console.log(AxiosError);

    //  Given error message
    if (message) {
        toaster.error(message);
    }

    //  Determine error message
    else {

        let errorMessage = null;
        if (AxiosError.response.data && AxiosError.response.data.validationErrors && "httpStatusCode" in AxiosError.response.data.validationErrors[0]) {
            errorMessage += AxiosError.response.data.httpStatusCode;

            if (AxiosError.response.data && "message" in AxiosError.response.data.validationErrors[0]) {
                errorMessage += ' - ' + AxiosError.response.data.validationErrors[0].message;
            }

        } else if (AxiosError.response.data && AxiosError.response.data.validationErrors && "message" in AxiosError.response.data.validationErrors[0]) {
            errorMessage = '';

            if (AxiosError.response.data && AxiosError.response.data.validationErrors[0].fieldName) {
                errorMessage = AxiosError.response.data.validationErrors[0].fieldName + ' ';
            }

            errorMessage += AxiosError.response.data.validationErrors[0].message;
        }
        if (errorMessage) {
            toaster.error(errorMessage);
        } else if (AxiosError.response.status === 400) {
            toaster.error('Bad request');
        } else if (AxiosError.response.status === 401) {
            toaster.error('Unauthorized - please refresh');
        } else if (AxiosError.response.status === 403) {
            toaster.error('Forbidden');
        } else if (AxiosError.response.status === 404) {
            toaster.error('Not found');
        } else if (AxiosError.response.status === 405) {
            toaster.error('Not allowed');
        } else if (AxiosError.response.status === 500) {
            toaster.error('Unknown error');
        } else if (AxiosError.response.status === 501) {
            toaster.error('Unknown error');
        }
    }
}
function extendLoginExpiration() {
    if (localStorage.getItem('authExp')) {
        const now = Math.round(new Date().getTime() / 1000);
        const timeLeft = localStorage.getItem('authExp') - now;
        const timeToAdd = 7200 - timeLeft;
        localStorage.setItem('authExp', Number(localStorage.getItem('authExp')) + timeToAdd);
    }
}